import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import { Col, Container, Row } from "reactstrap"
import Layout from "../components/Layout.js"

const offers = [
  {
    title: "Печатные платы",
    description: "Инженеры компании помогут в составлении технического задания на изготовление печатной платы любой сложности. С применением современных программных средств разработаем принципиальную электрическую схему в соответствии с ГОСТ отвечающую требованиям ЕСКД, чертёж печатной платы, сборочный чертёж изделия. На собственном оборудовании (если количество слоёв металлизации больше 2х, то с привлечением партнёров) произведём штучный и мелкосерийный выпуск печатных плат, осуществим выводной и SMD монтаж элементов с применением современного паяльного оборудования, покрытие защитным лаком (при необходимости компаундирование) в соотвествиее с требованиями предъявляемыми особыми режимами и условиями работы (агрессивные среды, повышенная влажность, высокие или низкие температуры эксплуатации изделия).",
    image: <StaticImage src="../images/offers-1.png" alt=""/>
  },  {
    title: "Механическая обработка",
    description: "На имеющемся оборудовании сможем восстановить или изготовить (из металла, композитных материалов и пластика), требуемые установочные и декоративные элементы, утраченные или разрушенные ручки управления, кнопки, колпачки сигнальных ламп, переходнки, штуцеры, кабельные вводы и пр. По требованию заказчика, можно привести практически любое устройство к состоянию неотличимому от нового.",
    image: <StaticImage src="../images/offers-2.png" alt=""/>
  },  {
    title: "Инфографика",
    description: "Осуществляем нанесение предупреждающих и информационных надписей на любых поверхностях (с помощью технологий металлографики, термотрансфера, тампопечати и шелкотрафаретной печати), нанесение маркировки, серийных номеров (в том числе с элементами защиты микрошрифтом), восстановим повреждённые металлические шильды или изготовим новые.",
    image: <StaticImage src="../images/offers-3.png" alt=""/>
  },
]

const OffersPage = () => {
  return (
      <Layout> 
        <title>Предложения</title>
        <Container>
            <h1 className="offers-heading"><span>Ohm Electronics —</span> ремонт электроники в&nbsp;полном смысле&nbsp;этого&nbsp;слова</h1>
            <p className="offers-top-text"> 
              Компания Ohm Electronics осуществляет всестадийный ремонт промышленной и нестандартной электроники, от производства планового ТО и замены отдельных комплектующих до полного капитально-восстановительного ремонта с приведением внешнего вида изделия в состояние неотличимого от нового. Произведём диагностику электронной части устройства, по требованию заказчика восстановим гальваническое и лакокрасочное покрытие, разработаем пакет конструкторской документации в соответствии с ЕСКД и ГОСТ, отреставрируем или изготовим установочные и декоративные элементы разрушенные или подверженные коррозии, механическим разрушениям и износу, нанесём предупреждающие и информационные надписи, заменим или восстановим панели приборов и пультов управления. Интегрируем в существующую систему сбора и обмена информации современные ПК на базе различных операционных систем, создадим полноценную систему мониторинга технологических процессов со сбором данных и их обработкой с имеющихся средств объективного контроля (самописцев, счётчиков времени наработки и количества запусков).
            </p>
            {offers.map(o => {
              return (
              <div className="offer-block">
                <div className="offer-title">{o.title}</div>
                <Row>
                  <Col>
                    <div className="offer-description">
                      {o.description}
                    </div>
                  </Col>
                  <Col style={{display: "contents"}}>
                    <div className="offer-photo">
                      {o.image}
                    </div>
                  </Col>
                </Row>
              </div>
            )})}
        </Container>
      </Layout>
  )
}

export default OffersPage
